/*
  _____ ___           
 |  __ \__ \    /\    
 | |  | | ) |  /  \   
 | |  | |/ /  / /\ \  
 | |__| / /_ / ____ \ 
 |_____/____/_/    \_\


This stylesheet adds new, and overrides existing Bootstrap styles - DO NOT edit the default Bootstrap stylesheet. */

/* Fonts */

@font-face { font-family: 'fontsite'; font-weight: 300; src: url('../fonts/fontsitesans-light-webfont.eot'); src: url('../fonts/fontsitesans-light-webfont.eot?#iefix') format('embedded-opentype'), url('../fonts/fontsitesans-light-webfont.woff2') format('woff2'), url('../fonts/fontsitesans-light-webfont.woff') format('woff'), url('../fonts/fontsitesans-light-webfont.ttf') format('truetype'), url('../fonts/fontsitesans-light-webfont.svg#fontsite_sanslight') format('svg'); }
@font-face { font-family: 'fontsite'; font-weight: 500; src: url('../fonts/fontsitesans-bold-webfont.eot'); src: url('../fonts/fontsitesans-bold-webfont.eot?#iefix') format('embedded-opentype'), url('../fonts/fontsitesans-bold-webfont.woff2') format('woff2'), url('../fonts/fontsitesans-bold-webfont.woff') format('woff'), url('../fonts/fontsitesans-bold-webfont.ttf') format('truetype'), url('../fonts/fontsitesans-bold-webfont.svg#fontsite_sansbold') format('svg'); }

/* Global */

body { font: 200 20px/28px fontsite, sans-serif; padding-top: 80px; background: #FFF; }
b, strong { font-weight: 900; }
a { color: #337ab7; }
a, a:hover, a:focus, .nav > li > a:hover, .nav > li > a:focus { outline: none !important; background: none; transition: all 0.25s ease; }
a:hover, a:focus { color: #EF6644; transition: all 0.25s ease; }
img { image-rendering: -webkit-optimize-contrast; }
h1, .h1, h2, .h2, h3, .h3, h4, .h4 { margin-top: 20px; color: #333; letter-spacing: -0.03em; }
h1 { font: 500 52px/55px "fontsite", serif; margin-bottom: 40px; }
h2 { font: 500 40px/52px "fontsite", serif; margin-bottom: 40px; }
h3 { font: 500 32px/40px "fontsite", serif; margin-bottom: 30px; }
h4 { font: 500 26px/32px "fontsite", serif; margin-bottom: 20px; }
h1 a, .h1 a, h2 a, .h2 a, h3 a, .h3 a, h4 a, a h4, .h4 a { color: #222; text-decoration: none; }
h1 a:hover, .h1 a:hover, h2 a:hover, .h2 a:hover, h3 a:hover, .h3 a:hover, h4 a:hover, .h4 a:hover { color: #EF6644; text-decoration: none; }
kbd { background-color: #999; box-shadow: none; color: #fff; font-size: 80%; font-weight: bold; padding: 3px 7px; }
abbr[title] { border-bottom: 0; }
blockquote { line-height: 135%; }
p { margin-top: 5px; }
hr { border: 0; border-top: 1px solid #DDD; margin: 15px 0; }
hr.border-dashed { border-top: 1px dashed #DDD; }
pre { white-space: pre-wrap; white-space: -moz-pre-wrap; white-space: -pre-wrap; white-space: -o-pre-wrap; word-wrap: break-word; }

/* Helper classes */

.m-0 { margin: 0px !important }
.m-5 { margin: 5px !important }
.m-10 { margin: 10px !important }
.m-15 { margin: 15px !important }
.m-20 { margin: 20px !important }
.m-25 { margin: 25px !important }
.m-30 { margin: 30px !important }
.mv-0 { margin-top: 0 !important; margin-bottom: 0 !important }
.mv-5 { margin-top: 5px !important; margin-bottom: 5px !important }
.mv-10 { margin-top: 10px !important; margin-bottom: 10px !important }
.mv-15 { margin-top: 15px !important; margin-bottom: 15px !important }
.mv-20 { margin-top: 20px !important; margin-bottom: 20px !important }
.mv-25 { margin-top: 25px !important; margin-bottom: 25px !important }
.mv-30 { margin-top: 30px !important; margin-bottom: 30px !important }
.mv-40 { margin-top: 40px !important; margin-bottom: 40px !important }
.mv-50 { margin-top: 50px !important; margin-bottom: 50px !important }
.mv-60 { margin-top: 60px !important; margin-bottom: 60px !important }
.mv-70 { margin-top: 70px !important; margin-bottom: 70px !important }
.mv-80 { margin-top: 80px !important; margin-bottom: 80px !important }
.mv-90 { margin-top: 90px !important; margin-bottom: 90px !important }
.mv-100 { margin-top: 100px !important; margin-bottom: 100px !important }
.mh-0 { margin-left: 0 !important; margin-right: 0 !important }
.mh-5 { margin-left: 5px !important; margin-right: 5px !important }
.mh-10 { margin-left: 10px !important; margin-right: 10px !important }
.mh-15 { margin-left: 15px !important; margin-right: 15px !important }
.mh-20 { margin-left: 20px !important; margin-right: 20px !important }
.mh-30 { margin-left: 30px !important; margin-right: 30px !important }
.mt-0 { margin-top: 0 !important }
.mt-5 { margin-top: 5px !important }
.mt-10 { margin-top: 10px !important }
.mt-15 { margin-top: 15px !important }
.mt-20 { margin-top: 20px !important }
.mt-25 { margin-top: 25px !important }
.mt-30 { margin-top: 30px !important }
.mt-35 { margin-top: 35px !important }
.mt-40 { margin-top: 40px !important }
.mt--40 { margin-top: -40px !important }
.mt-50 { margin-top: 50px !important }
.mt-60 { margin-top: 60px !important }
.mt--60 { margin-top: -60px !important }
.mt-70 { margin-top: 70px !important }
.mt-80 { margin-top: 80px !important }
.mt-90 { margin-top: 90px !important }
.mt-100 { margin-top: 100px !important }
.mt--100 { margin-top: -100px !important }
.mb-0 { margin-bottom: 0 !important }
.mb-5 { margin-bottom: 5px !important }
.mb-10 { margin-bottom: 10px !important }
.mb-15 { margin-bottom: 15px !important }
.mb-20 { margin-bottom: 20px !important }
.mb-25 { margin-bottom: 25px !important }
.mb-30 { margin-bottom: 30px !important }
.mb-40 { margin-bottom: 40px !important }
.mb-50 { margin-bottom: 50px !important }
.mb-60 { margin-bottom: 60px !important }
.mb-70 { margin-bottom: 70px !important }
.mb-80 { margin-bottom: 80px !important }
.mb-90 { margin-bottom: 90px !important }
.mb-100 { margin-bottom: 100px !important }
.mr-0 { margin-right: 0 !important }
.mr-5 { margin-right: 5px !important }
.mr-10 { margin-right: 10px !important }
.mr-15 { margin-right: 15px !important }
.mr-20 { margin-right: 20px !important }
.mr-25 { margin-right: 25px !important }
.mr-30 { margin-right: 30px !important }
.mr-40 { margin-right: 40px !important }
.mr--40 { position: relative; right: -40px; }
.mr-50 { margin-right: 50px !important }
.mr-60 { margin-right: 60px !important }
.mr--60 { position: relative; right: -60px; }
.mr-70 { margin-right: 70px !important }
.mr-80 { margin-right: 80px !important }
.mr-90 { margin-right: 90px !important }
.mr-100 { margin-right: 100px !important }
.ml-0 { margin-left: 0 !important }
.ml-5 { margin-left: 5px !important }
.ml-10 { margin-left: 10px !important }
.ml-15 { margin-left: 15px !important }
.ml-20 { margin-left: 20px !important }
.ml-25 { margin-left: 25px !important }
.ml-30 { margin-left: 30px !important }
.ml-40 { margin-left: 40px !important }
.ml-50 { margin-left: 50px !important }
.ml-60 { margin-left: 60px !important }
.ml-70 { margin-left: 70px !important }
.pv-0 { padding-top: 0 !important; padding-bottom: 0 !important; }
.pv-5 { padding-top: 5px !important; padding-bottom: 5px !important; }
.pv-10 { padding-top: 10px !important; padding-bottom: 10px !important; }
.pv-15 { padding-top: 15px !important; padding-bottom: 15px !important; }
.pv-20 { padding-top: 20px !important; padding-bottom: 20px !important; }
.pv-25 { padding-top: 25px !important; padding-bottom: 25px !important; }
.pv-30 { padding-top: 30px !important; padding-bottom: 30px !important; }
.pv-40 { padding-top: 40px !important; padding-bottom: 40px !important; }
.pv-50 { padding-top: 50px !important; padding-bottom: 50px !important; }
.pv-60 { padding-top: 60px !important; padding-bottom: 60px !important; }
.pv-70 { padding-top: 70px !important; padding-bottom: 70px !important; }
.pv-80 { padding-top: 80px !important; padding-bottom: 80px !important; }
.pv-90 { padding-top: 90px !important; padding-bottom: 90px !important; }
.pv-100 { padding-top: 100px !important; padding-bottom: 100px !important; }
.ph-0 { padding-left: 0 !important; padding-right: 0 !important; }
.ph-5 { padding-left: 5px !important; padding-right: 5px !important; }
.ph-10 { padding-left: 10px !important; padding-right: 10px !important; }
.ph-15 { padding-left: 15px !important; padding-right: 15px !important; }
.ph-20 { padding-left: 20px !important; padding-right: 20px !important; }
.ph-25 { padding-left: 25px !important; padding-right: 25px !important; }
.ph-30 { padding-left: 30px !important; padding-right: 30px !important; }
.ph-40 { padding-left: 40px !important; padding-right: 40px !important; }
.ph-50 { padding-left: 50px !important; padding-right: 50px !important; }
.ph-60 { padding-left: 60px !important; padding-right: 60px !important; }
.ph-70 { padding-left: 70px !important; padding-right: 70px !important; }
.ph-80 { padding-left: 80px !important; padding-right: 80px !important; }
.ph-90 { padding-left: 90px !important; padding-right: 90px !important; }
.ph-100 { padding-left: 100px !important; padding-right: 100px !important; }
.p-0 { padding: 0px !important }
.p-5 { padding: 5px !important }
.p-10 { padding: 10px !important }
.p-15 { padding: 15px !important }
.p-20 { padding: 20px !important }
.p-25 { padding: 25px !important }
.p-30 { padding: 30px !important }
.p-40 { padding: 40px !important }
.pt-0 { padding-top: 0 !important }
.pt-5 { padding-top: 5px !important }
.pt-10 { padding-top: 10px !important }
.pt-15 { padding-top: 15px !important }
.pt-20 { padding-top: 20px !important }
.pt-25 { padding-top: 25px !important }
.pt-30 { padding-top: 30px !important }
.pt-35 { padding-top: 35px !important }
.pt-40 { padding-top: 40px !important }
.pt-50 { padding-top: 50px !important }
.pt-60 { padding-top: 60px !important }
.pt-70 { padding-top: 70px !important }
.pt-75 { padding-top: 75px !important }
.pt-80 { padding-top: 80px !important }
.pt-90 { padding-top: 90px !important }
.pt-100 { padding-top: 100px !important }
.pb-0 { padding-bottom: 0 !important }
.pb-5 { padding-bottom: 5px !important }
.pb-10 { padding-bottom: 10px !important }
.pb-15 { padding-bottom: 15px !important }
.pb-20 { padding-bottom: 20px !important }
.pb-30 { padding-bottom: 30px !important }
.pb-40 { padding-bottom: 40px !important }
.pb-50 { padding-bottom: 50px !important }
.pb-60 { padding-bottom: 60px !important }
.pb-70 { padding-bottom: 70px !important }
.pb-80 { padding-bottom: 80px !important }
.pb-90 { padding-bottom: 90px !important }
.pb-100 { padding-bottom: 100px !important }
.pr-0 { padding-right: 0 !important }
.pr-5 { padding-right: 5px !important }
.pr-10 { padding-right: 10px !important }
.pr-15 { padding-right: 15px !important }
.pr-20 { padding-right: 20px !important }
.pr-25 { padding-right: 25px !important }
.pr-30 { padding-right: 30px !important }
.pr-35 { padding-right: 35px !important }
.pr-40 { padding-right: 40px !important }
.pr-50 { padding-right: 50px !important }
.pr-60 { padding-right: 60px !important }
.pr-70 { padding-right: 70px !important }
.pr-80 { padding-right: 80px !important }
.pr-90 { padding-right: 90px !important }
.pr-100 { padding-right: 100px !important }
.pl-0 { padding-left: 0 !important }
.pl-5 { padding-left: 5px !important }
.pl-10 { padding-left: 10px !important }
.pl-15 { padding-left: 15px !important }
.pl-20 { padding-left: 20px !important }
.pl-30 { padding-left: 30px !important }
.pl-40 { padding-left: 40px !important }
.pl-50 { padding-left: 50px !important }
.truncate { text-overflow: ellipsis; white-space: nowrap; overflow: hidden; }
.splitter { background: transparent; border-left: 1px solid #EEE; display: inline-block; margin: 0 9px; width: 1px; }
.help { cursor: help; }
.btn.disabled, .btn[disabled], fieldset[disabled] .btn { opacity: .25; }
ul.timeline { position: relative; padding-left: 25px; line-height: 120%; }
ul.timeline li { list-style-type: none!important; }
ul.timeline > li { margin: 15px 0 0; padding-left: 0; }
ul.timeline { list-style-type: none; position: relative; padding-left: 25px; line-height: 120%; }
ul.timeline:before { content: ' '; background: #CCC; display: inline-block; position: absolute; left: 9px; width: 2px; height: 100%; z-index: 400; }
ul.timeline > li:before { content: ' '; background: #F9F9F9 url(../images/bg-light-grey.jpg); display: inline-block; position: absolute; border-radius: 50%; border: 3px solid #87b340; left: 4px; width: 12px; height: 12px; z-index: 400; margin-top: 3px; }
.notification-dot { vertical-align: super; }
.speaker { margin-top: -60px; }

/* Bootstrap classes */

.img-rounded { border-radius: 25px; }
.img-responsive { margin-left: auto; margin-right: auto; }
.alert-dismissable .close, .alert-dismissible .close { right: 0; }
.alert { border-radius: 0; border: 0; font-size: 18px; padding: 10px 15px; margin-bottom: 40px; }
.alert a { text-decoration: underline; color: #FFF !important; }
.alert a.btn { text-decoration: none; }
.alert a:hover { text-decoration: underline; }
.list-inline > li { padding: 0 10px; }

/* Borders */

.no-border { border: none !important; }
.border-grey { border: 1px solid #CCC !important; }
.border-danger { border: 1px solid #db4e32 !important; }
.media.border-danger { border-radius: 5px; padding: 5px; border: 2px solid #db4e32 !important; }
.border-lightgrey { border: 1px solid #DDD !important; }
.border-bottom-grey { border-bottom: 1px solid #CCC !important; }
.border-bottom-lightgrey { border-bottom: 1px solid #DDD !important; }

/* Header & Main Navigation */

header { transition: top 0.2s ease-in-out; position: fixed; top: 0; width: 100%; ; }
.nav-up { top: -75px; }
.navbar { border: none; margin-bottom: none; min-height: 75px; background-color: #FFF; opacity: .97; }
.navbar-nav { margin-left: 10px; }
.navbar-nav > li > a { line-height: 44px; color: #222; font-weight: 500; font-size: 80%; text-transform: uppercase; }
.navbar-nav > li.active > a { background: none; color: #000; text-decoration: underline; }
.navbar-nav > li > a:hover { background: none; color: #EF6644; text-decoration: none !important; }
.navbar-right { position: relative; margin-right: 5px; }
.navbar-right img { margin-right: 5px; box-shadow: 1px 1px 1px rgba(0,0,0,0.1); }
.navbar-nav > li > .dropdown-menu a { color: #444; padding: 5px 15px; }
.navbar .btn { letter-spacing: 0.025em; font-size: 16px; }
.navbar-nav ul.dropdown-menu li a { font-weight: 600 !important; }
.navbar-form { padding: 0 15px 0 0; }
.navbar-btn { margin: 12px 6px 6px !important; padding: 10px 15px 6px; line-height: 34px; }
.navbar-toggle { margin-top: 18px; }
.navbar-brand { margin: 10px 30px 10px 10px; padding: 0; }
a.navbar-brand img { width: 60px; height: 60px; }
.navbar-collapse.in { background-color: #FFF; }
.nav.navbar-nav, .navbar-right { margin-top: 5px; }
.navbar-right .nav.navbar-nav { margin-top: 0; }
.navbar .label { border-radius: 0.25em; color: #fff; display: inline; font-size: 65%; font-weight: 300; letter-spacing: -0.015em; line-height: 1; padding: 0.3em 0.4em 0.2em; text-align: center; vertical-align: top; white-space: nowrap; }
.dropdown-header { color: #BBB; display: block; font-size: 70%; font-weight: bold; padding: 11px 15px 5px; text-transform: uppercase; }
.dropdown-menu { border-radius: 3px !important; box-shadow: 1px 1px 1px rgba(0,0,0,0.1); font-size: inherit; border-color: #DDD; min-width: 200px; float: right; right: 0; }
.dropdown-menu > li > a { font-size: 90%; padding: 7px 15px; white-space: normal; }
.dropdown-menu li a .badge { background-color: #EF6644; padding: 3px 6px; }
.dropdown-menu .glyphicon, .list-group-item .glyphicon, .dropdown-menu .fa, .list-group-item .fa, .user .fa { margin-right: 6px; opacity: .75; }
.dropdown-menu.tinkercad-for { width: 420px; font-size: 80%; }
.dropdown-menu.tinkercad-for li { width: 24%; display: inline-block; text-align: center; }
.dropdown-menu.tinkercad-for li.dropdown-header, .dropdown-menu.tinkercad-for li.divider { width: 100% !important; text-align: left !important; }
.dropdown-menu-left { right: 0; left: auto; }
.nav .open > a, .nav .open > a:hover, .nav .open > a:focus { background: none; border: none; }
.icon-bar { background-color: #333; border-radius: 2px !important; height: 4px !important; width: 30px !important; margin-top: 5px; }

/* Tab Nav */

.tab-content { padding: 50px 40px 20px 40px; border: 1px solid #DDD; border-top: none; background-color: #FFF; }
.nav-tabs > li { border-bottom: none; margin-bottom: -2px; }
.nav-tabs li a:hover, .nav-tabs li.active a:hover, .nav-tabs li a:focus, .nav-tabs li.focus a:hover { border-bottom: none !important; }
.nav-tabs > li.active > a, .nav-tabs > li.active > a:hover, .nav-tabs > li.active > a:focus { background: #F9F9F9 url(../images/bg-light-grey.jpg); }
.modal .modal-body .nav-tabs > li.active > a, .modal .modal-body .nav-tabs > li.active > a:hover, .modal .modal-body .nav-tabs > li.active > a:focus { background: #FFF; border-bottom: 0; }
.nav-tabs > li > a { padding: 16px 25px; border-bottom: none; color: #333; }

/* Buttons */

.btn { padding: 6px 16px; border-radius: 30px; transition: all 0.25s ease; font-weight: inherit; }
.btn-xs { font-size: 15px; font-weight: 400; padding: 2px 8px 0; margin: 0; }
.btn-default { border: 1px solid #CCC; background: transparent; }
.btn-link { color: #444; }
.btn-link:hover, .btn-link:focus { color: #EF6644 !important; text-decoration: none !important; }
.btn-link.active { font-weight: 900 !important; color: #EF6644; border: none; box-shadow: none !important; }
.btn-info a:hover, .thumbnail .btn-info a:hover { color: #FFF !important; }
.btn-info, .label-info, .progress-bar-info { background-color: #6ba3b9; }
.btn-success, .label-success, .progress-bar-success { background-color: #339900; }
.btn-warning, .label-warning, .progress-bar-warning { background-color: #df8738; }
.btn-danger, .label-danger, .progress-bar-danger { background-color: #ff4037; }
.btn-danger .text-muted { color: #FFF !important; }
.btn-flex { display: flex; align-items: stretch; align-content: stretch; }
.btn-flex .btn:first-child { flex-grow: 1; }
.btn-lg, .btn-group-lg > .btn { padding: 13px 22px; font-size: 95%; }

/* Text */

pre { color: #999; font-size: 12px; line-height: 14px; padding: 7px 10px; }
.lead { font: 200 28px/36px "fontsite", sans-serif; letter-spacing: -1px; }
.close { font-size: 42px; }
.text-center { text-align: center; }
.text-primary { color: #0696D7; }
.text-black { color: #000; }
.text-white, .bg-black { color: #FFF; }
.text-info { color: #6ba3b9; }
.text-yellow { color: #FFB600; }
.text-success { color: #339900; }
.text-warning { color: #df8738; }
.text-muted { color: #888; }
.text-danger, a.text-danger { color: #db4e32; }
.text-normal { font-weight: 200 !important; }
.help-block { font-size: 14px; line-height: 18px; margin-top: 13px; }
.badge { padding: 3px 5px; }
.list-group-item.active { background-color: #74BDE2; color: #FFF; }
.list-group-item.active { border-color: #56AFDB; }
.list-group-item > .badge { margin-top: 4px; background-color: #ef6644; }
.form-group { margin-bottom: 20px; }
.label { border-radius: 3px; padding: 5px 8px 3px; vertical-align: middle; }
.radio label, .checkbox label { font-weight: 200; }
.text-xs { line-height: 120%; font-size: 70%; }
.text-sm { line-height: 130%; font-size: 80%; }
.text-xl { font-size: 320%; line-height: 120%; }
.text-xl small { font-size: 50%; }

/* Backgrounds */

.no-bg { background: none !important; }
.bg-white { background-color: #FFF !important; }
.bg-black { background-color: #000 !important; color: #FFF; }
.bg-black a { color: #FFF; }
.bg-warning { color: #FFF; background-color: #EE6643; }
.bg-warning blockquote, .bg-warning blockquote a { color: #ffe9ca; }
.bg-warning blockquote footer h4 { margin: 0; padding-top: 4px; }
.bg-warning blockquote footer { color: #803425; margin-bottom: 20px; line-height: 16px; background: none; }
.bg-warning blockquote footer a { color: #803425; }
.bg-warning h1, .bg-warning h2, .bg-warning h3, .bg-warning h4, .bg-success h1, .bg-success h2, .bg-success h3, .bg-success h4, .bg-primary h1, .bg-primary h2, .bg-primary h3, .bg-primary h4, .bg-primary h1 small, .bg-primary h2 small, .bg-primary h3 small, .bg-primary h4 small { color: #FFF; }
.bg-primary .btn-primary { border-color: #fff; }
.bg-info, .alert-info { background-color: #5fc0e9; color: #FFF; }
.bg-success, .alert-success { background-color: #339900; color: #E6F5E6; }
.bg-success a { color: #E6F5E6 !important; }
.bg-warning, .alert-warning { background-color: #DF8738; color: #F8EADD; }
.bg-danger, .alert-danger { background-color: #db4e32; color: #F8D9D3; }
.bg-primary, .btn-primary, .label-primary, .progress-bar { background-color: #0696D7; }
.bg-primary a { color: #FFF; }
.bg-primary .text-muted { color: #a5cfec!important; }
.bg-fade { background-position: 1% 50% !important; display: block; background-size: 100% !important; background-repeat: no-repeat!important; transition: all .42s ease; }

/* Panels */

.panel { border-color: #DDD; }
.panel-success, .border-success { border-color: #339900; }
.panel-heading { background-color: #F9F9F9; }
.panel-body a.settings { position: absolute; margin: 0; top: 10px; right: 20px; }
.panel-body a.settings { color: #999; }
.panel-body .thumbnail { margin: 0; }
.panel-body .thumbnail .panel-body h4 { text-overflow: ellipsis; white-space: nowrap; overflow: hidden; }

/* Tables */

.table > thead > tr > td.success, .table > tbody > tr > td.success, .table > tfoot > tr > td.success, .table > thead > tr > th.success, .table > tbody > tr > th.success, .table > tfoot > tr > th.success, .table > thead > tr.success > td, .table > tbody > tr.success > td, .table > tfoot > tr.success > td, .table > thead > tr.success > th, .table > tbody > tr.success > th, .table > tfoot > tr.success > th { background-color: #ebf8e6; }
.table-hover > tbody > tr > td.success:hover, .table-hover > tbody > tr > th.success:hover, .table-hover > tbody > tr.success:hover > td, .table-hover > tbody > tr:hover > .success, .table-hover > tr { vertical-align: middle !important; }
tbody > tr.success:hover > th { background-color: #ddf1d6; }
.table-fixed thead { border-bottom: none; }
.table-fixed thead, .table-fixed tbody tr { display: table; width: 100%; table-layout: fixed; }
.table-fixed tbody { display: block; max-height: 350px; overflow: auto; }
.table-condensed > thead > tr > th, .table-condensed > tbody > tr > th, .table-condensed > tfoot > tr > th, .table-condensed > thead > tr > td, .table-condensed > tbody > tr > td, .table-condensed > tfoot > tr > td { padding: 0 5px; }
.table > tbody > tr > td, .table > tbody > tr > td a { vertical-align: middle; }

/*Modal*/

.modal-header { border: none; border-bottom: 1px solid #dfe3e8; padding: 20px 25px 15px; }
.modal-header .close { margin-top: -5px; }
.modal-header.bg-primary, .modal-header.bg-danger, .modal-header.bg-info { color: #fff; }
.modal-header.bg-primary .close, .modal-header.bg-danger .close, .modal-header.bg-info .close { color: #FFF; opacity: 0.75; }
.modal-body { padding: 25px; background: #FFF; }
#settings-modal .modal-body { background-color: #F9F9F9; }
.modal-content { box-shadow: 0 2px 10px rgba(0, 0, 0, 0.15); border-radius: 5px; }
.modal-content .thumbnails { margin: 5px 0; }
.modal-content .thumbnails > div { padding: 0; cursor: pointer; }
.modal-footer { border: none; margin: 0; padding: 25px; background: #fff; }
.modal-lg .modal-title { line-height: 1em; margin: 0; }
.modal-sm { width: 500px; }
#design-detail .modal-body {  }
.carousel-control.right { background-image: linear-gradient(to right, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.15) 100%); }
.carousel-control.left { background-image: linear-gradient(to right, rgba(0, 0, 0, 0.15) 0%, rgba(0, 0, 0, 0) 100%); }
.design-quick-view .modal-header { padding: 25px 25px 15px; }
.design-quick-view .modal-header .close { margin-top: -2px; }
.design-quick-view .modal-content { border: none; box-shadow: 0 !important; }
.design-quick-view .tab-content { padding: 20px 20px 0; }
.license a { bottom: 20px; height: 20px; opacity: 0.25; position: absolute; right: 15px; width: 65px; z-index: 2; cursor: help; }
inut-group .input-lg, .input-lg { height: 52px; }
#news-ticker { text-overflow: ellipsis; white-space: nowrap; overflow: hidden; }

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  
.front, .back { -ms-backface-visibility: visible; backface-visibility: visible; }
.back { visibility: hidden; -ms-transition: all 0.2s cubic-bezier(.92, .01, .83, .67); }
.front { z-index: 4; }
.card-container:not(.manual-flip):hover .back,  .card-container.manual-flip.hover .back { z-index: 5; visibility: visible; }
}
 @-moz-document url-prefix() {
fieldset { display: table-cell; }
}

@media (max-width: 768px) {
.text-center-xs { text-align: center !important; }
.label { padding: 2px 7px; }
.navbar-nav > li > a { color: #444; line-height: 28px; }
.banner p { text-align: center }
.navbar-fixed-top .navbar-collapse, .navbar-fixed-bottom .navbar-collapse { text-align: center; }
navbar-header { width: 250px; }
.panel-body .row { margin: 0 -10px; }
.side-nav { margin: 0 -10px; }
.container-fluid, .container { padding: 20px; }
.thumbnail { margin-bottom: 20px; }
.no-pull-left-xs, .no-pull-right-xs { float: none!important; }
.speaker { margin-top: 0; }
}

@media (min-width: 768px) {
.nav > li > a { padding-right: 10px; padding-left: 10px; }
.navbar > .container .navbar-brand, .navbar > .container-fluid .navbar-brand { margin-left: -5px; }
.container-fluid, .container { padding: 30px; }
.thumbnail { margin-bottom: 30px; }

}

@media (max-width: 992px) {
.text-center-sm { text-align: center !important; }
.navbar-header { float: none; }
.navbar-left, .navbar-right { float: none !important; }
.navbar-toggle { display: block; }
.navbar-collapse { border-top: 1px solid transparent; box-shadow: inset 0 1px 0 rgba(255,255,255,0.1); }
.navbar-fixed-top { top: 0; border-width: 0 0 1px; }
.navbar-collapse.collapse { display: none!important; }
.navbar-nav { float: none!important; margin-top: 7.5px; }
.navbar-nav>li { float: none; }
.navbar-nav>li>a { padding-top: 10px; padding-bottom: 10px; }
.collapse.in { display: block !important; }
.banner-index { position: relative; bottom: -40px; }
.no-pull-left-sm, .no-pull-right-sm { float: none!important; }
.no-bg-md { background-image: none!important; }
.bg-fade { background-size: cover!important; }
}

@media (min-width: 992px) {
.text-center-md { text-align: center !important; }
.sticky { position: fixed; transition: all 0.5s ease; margin: 0 30px 0 0; }
.stuck { margin-top: -85px; transition: all 0.5s ease; }
.banner-index { position: relative; bottom: -40px; }
.no-bg-lg { background-image: none!important; }
}

@media (min-width: 1200px) {
.nav > li > a { padding: 12px 16px; }
.design-quick-view .modal-footer { margin: 0; text-align: right; }
.modal-md { width: 800px; }
.modal-lg { width: 1000px; }
.banner-index { position: relative; bottom: -20px; }
.text-center-lg { text-align: center !important; }
}

@media (min-width: 1500px) {
#design-detail .btn-group-justified .btn-primary { width: 8%; }
.modal-md { width: 1000px; }
.modal-lg { width: 1100px; }
.banner-index { position: relative; bottom: -80px; }
.text-center-xl { text-align: center !important; }

}

/* Author: Marc van Nieuwenhuijzen / WebVakman.nl */
@media (min-width: 1200px) and (max-width: 1599px) {
.hidden-lg { display: none !important; }
}
.visible-xl-block, .visible-xl-inline, .visible-xl-inline-block, .visible-xl { display: none !important; }

@media (min-width: 1600px) {
.no-bg-xl { background-image: none!important; }
.col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12 { float: left; }
.col-xl-12 { width: 100%; }
.col-xl-11 { width: 91.66666667%; }
.col-xl-10 { width: 83.33333333%; }
.col-xl-9 { width: 75%; }
.col-xl-8 { width: 66.66666667%; }
.col-xl-7 { width: 58.33333333%; }
.col-xl-6 { width: 50%; }
.col-xl-5 { width: 41.66666667%; }
.col-xl-4 { width: 33.33333333%; }
.col-xl-3 { width: 25%; }
.col-xl-2 { width: 16.66666667%; }
.col-xl-1 { width: 8.33333333%; }
.col-xl-pull-12 { right: 100%; }
.col-xl-pull-11 { right: 91.66666667%; }
.col-xl-pull-10 { right: 83.33333333%; }
.col-xl-pull-9 { right: 75%; }
.col-xl-pull-8 { right: 66.66666667%; }
.col-xl-pull-7 { right: 58.33333333%; }
.col-xl-pull-6 { right: 50%; }
.col-xl-pull-5 { right: 41.66666667%; }
.col-xl-pull-4 { right: 33.33333333%; }
.col-xl-pull-3 { right: 25%; }
.col-xl-pull-2 { right: 16.66666667%; }
.col-xl-pull-1 { right: 8.33333333%; }
.col-xl-pull-0 { right: auto; }
.col-xl-push-12 { left: 100%; }
.col-xl-push-11 { left: 91.66666667%; }
.col-xl-push-10 { left: 83.33333333%; }
.col-xl-push-9 { left: 75%; }
.col-xl-push-8 { left: 66.66666667%; }
.col-xl-push-7 { left: 58.33333333%; }
.col-xl-push-6 { left: 50%; }
.col-xl-push-5 { left: 41.66666667%; }
.col-xl-push-4 { left: 33.33333333%; }
.col-xl-push-3 { left: 25%; }
.col-xl-push-2 { left: 16.66666667%; }
.col-xl-push-1 { left: 8.33333333%; }
.col-xl-push-0 { left: auto; }
.col-xl-offset-12 { margin-left: 100%; }
.col-xl-offset-11 { margin-left: 91.66666667%; }
.col-xl-offset-10 { margin-left: 83.33333333%; }
.col-xl-offset-9 { margin-left: 75%; }
.col-xl-offset-8 { margin-left: 66.66666667%; }
.col-xl-offset-7 { margin-left: 58.33333333%; }
.col-xl-offset-6 { margin-left: 50%; }
.col-xl-offset-5 { margin-left: 41.66666667%; }
.col-xl-offset-4 { margin-left: 33.33333333%; }
.col-xl-offset-3 { margin-left: 25%; }
.col-xl-offset-2 { margin-left: 16.66666667%; }
.col-xl-offset-1 { margin-left: 8.33333333%; }
.col-xl-offset-0 { margin-left: 0; }
.visible-xl { display: block !important; }
table.visible-xl { display: table; }
tr.visible-xl { display: table-row !important; }
th.visible-xl, td.visible-xl { display: table-cell !important; }
.visible-xl-block { display: block !important; }
.visible-xl-inline { display: inline !important; }
.visible-xl-inline-block { display: inline-block !important; }
.hidden-xl { display: none !important; }
}